import React from 'react'

import { StreamSelectionControl } from '../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroom'

import { useWatermark } from './hooks/useWatermark'

interface WatermarkProps {
  stream: StreamSelectionControl
}

export const Watermark = (props: WatermarkProps) => {
  useWatermark(props)

  return <></>
}
