export const videoWrapperClassName = 'video-stream-wrapper'
export const watermarkId = 'visible-watermark'

// we're doing it this way to make it less convenient to manually delete the watermark
export function turnOnWatermark(arr, dateString, styles) {
  try {
    const wrapperEl = document.querySelector(`.${videoWrapperClassName}`)
    if (!wrapperEl) throw new Error('No video wrapper element')
    // find position to overlay video corner
    const videoEl = wrapperEl.querySelector('video')
    const vidWidth = videoEl?.videoWidth
    const vidHeight = videoEl?.videoHeight
    const vidRect = videoEl?.getBoundingClientRect()
    if (vidRect && vidWidth && vidHeight) {
      const heightRatio = vidRect.height / vidHeight
      const widthRatio = vidRect.width / vidWidth
      if (heightRatio < widthRatio) {
        const ratio = vidRect.height / vidHeight
        const actualWidth = ratio * vidWidth
        const leftPos = (vidRect.width - actualWidth) / 2
        styles.left = leftPos + 'px'
        styles.top = '0'
      } else {
        const ratio = vidRect.width / vidWidth
        const actualHeight = ratio * vidHeight
        const wrapperRect = wrapperEl.getBoundingClientRect() // the align-items center on the wrapper means we need to get this dimention when the heightRatio is greater than the widthRatio
        const topPos = (wrapperRect.height - actualHeight) / 2
        styles.top = topPos + 'px'
        styles.left = '0'
      }
    }
    styles.display = ''
    // create a new element
    const oldEl = wrapperEl.querySelector(`#${watermarkId}`)
    const newWatermarkEl = document.createElement('div')
    newWatermarkEl.id = watermarkId
    wrapperEl.append(newWatermarkEl)
    const info = arr.map((text) => `<div>${text}</div>`).join('')
    const timeDiv = `<div>${dateString}</div>`
    newWatermarkEl.innerHTML = info + timeDiv
    for (const [prop, val] of Object.entries(styles)) {
      newWatermarkEl.style[prop] = val
    }
    oldEl?.remove()
  } catch (err) {
    console.error('Tampering with the watermark')
  }
}
export function turnOffWatermark(styles) {
  try {
    const watermarkEl = document.querySelector(`#${watermarkId}`)
    styles.display = 'none'
    for (const [prop, val] of Object.entries(styles)) {
      // @ts-ignore
      watermarkEl.style[prop] = val
    }
  } catch {}
}
