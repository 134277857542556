import axios, { AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { AccountMeProps } from '../Components/Header/hooks/useHeaderInfo'
import {
  EBSConfig,
  IJWtUser,
  IOrganizationFeatures,
  IPermission,
  IPostConnectivityLog,
  IRoomAuthorize,
  IRoomFeatures,
  IRoomMFA,
  IRoomPermissions,
  IVerifyRoomMFA,
  RoomAccess,
  RoomAPI,
  RoomInvitesAPI,
  RoomMinimal,
  RoomServerUrl,
} from '../Models/apiEntities'
import { useEnvironment } from '../Providers/EnvironmentProvider'

export interface IAccountAuthenticateParams {
  email: string
  password: string
  displayName?: string
  tracking_id?: string
  two_factor_verification_code?: string
}

export interface IEnrollAuthenticatorParams {
  authenticator_type: string
  barcode_uri: string
  secret: string
  recovery_codes?: string[]
}

export interface SpecialInviteValidationResponse {
  isUsed: boolean
  valid: boolean
  roomId: string
}

interface AuthAccountProps {
  authenticate: (params: IAccountAuthenticateParams) => Promise<IJWtUser>
  jitUser: () => Promise<IJWtUser>
}

interface PermissionsProps {
  getPermissions: (params: any) => Promise<IPermission>
}

export interface restAPIContext {
  AuthAccount: AuthAccountProps
  Permission: PermissionsProps
}

enum ApiInstance {
  base,
  auth,
  mod
}

const useApi = () => {
  const baseURL = useEnvironment().restApiUrl
  const authURL = useEnvironment().authUrl
  const moderationURL = useEnvironment().moderationApiUrl

  return useMemo(() => {
    const baseInstance = axios.create({
      baseURL,
      timeout: 15000,
      withCredentials: true,
      headers: {
        'accept-version': '2',
      },
    })

    const authInstance = axios.create({
      baseURL: authURL,
      timeout: 15000,
      withCredentials: true,
      headers: {
        'accept-version': '2',
      },
    })

    const moderationInstance = axios.create({
      baseURL: moderationURL,
      timeout: 15000,
      withCredentials: true,
      headers: {
        'accept-version': '2',
      },
    })

    const getInstance = (instance : ApiInstance = ApiInstance.base) => {
      switch (instance) {
        case ApiInstance.base:
          return baseInstance;
        case ApiInstance.auth:
          return authInstance;
        case ApiInstance.mod:
          return moderationInstance;
        default:
          return baseInstance;
      }
    }

    const responseBody = (response: AxiosResponse) => response.data

    const requests = {
      get: (url: string, instance?: ApiInstance) =>
        getInstance(instance).get(url).then(responseBody),
      post: (url: string, body: {}, instance?: ApiInstance) =>
        getInstance(instance).post(url, body).then(responseBody),
      patch: (url: string, body: {}, instance?: ApiInstance) =>
        getInstance(instance).patch(url, body).then(responseBody),
      delete: (url: string, body: {}, instance?: ApiInstance) =>
        getInstance(instance).delete(url, { data: body }).then(responseBody),
    }

    const AuthAccount = {
      authenticate: (params: IAccountAuthenticateParams): Promise<IJWtUser> =>
        requests.post('/', params, ApiInstance.auth).then((res) => res.data),
      jitUser: (): Promise<IJWtUser> => requests.get('/sso/jitUser', ApiInstance.auth).then((res) => res.data),
      logout: (): Promise<[]> => requests.get('/logout', ApiInstance.auth).then((res) => res.data),
      getRoomMfa: (roomHash: string): Promise<IRoomMFA> =>
        requests.get(`room/${roomHash}/mfa`, ApiInstance.auth).then((res) => res.data),
      setRoomMfaToken: (roomHash: string, password: string): Promise<any> =>
        requests.post(`room/${roomHash}/mfa/token`, { password }, ApiInstance.auth).then((res) => res.data),
      confirmMfa: (mfa_code: string, two_factor_activation_token: string): Promise<IVerifyRoomMFA> =>
        requests
          .post(`mfa/confirm`, { mfa_code, two_factor_activation_token }, ApiInstance.auth)
          .then((res) => res.data),
      verifyRoomMfa: (roomHash: string, mfaCode: string): Promise<IVerifyRoomMFA> =>
        requests
          .post(`room/${roomHash}/verifyMfa`, { mfa_code: mfaCode }, ApiInstance.auth)
          .then((res) => res.data),
      me: (): Promise<AccountMeProps> => requests.get('accounts/me', ApiInstance.auth).then((res) => res.data),
      create: (params: IAccountAuthenticateParams): Promise<any> =>
        requests.post('/signup', params, ApiInstance.auth).then((res) => res),
      resetPassword: (payload: { requestToken: string; newPassword: string }): Promise<any> =>
        requests.post('/accounts/reset-password', payload, ApiInstance.auth),
      generateOtp: (): Promise<IEnrollAuthenticatorParams> =>
        requests.post('qr-code', { }, ApiInstance.auth).then((res) => res.data),
      guestLogin: (displayName, email): Promise<void> => requests.post('accounts/guest', { displayName, email }, ApiInstance.auth).then((res) => res.data)
    }

    const Account = {
      getUnusedCredits: (): Promise<any> =>
        requests.get('/api/accounts/me/credits').then((res) => res.data),
      createOrRefreshStreamKey: (): Promise<void> => 
        requests.post(`/api/accounts/streamKey`, {}).then((res) => res.data),
    }

    const ConnectivityLogs = {
      postLog: (roomId: string, payload: IPostConnectivityLog): Promise<any> =>
        requests.post(`/api/connectivity_logs/${roomId}`, payload).then((res) => res.data),
      getLogByUserId: (roomId: string, userId: string): Promise<IPostConnectivityLog> => {
        const cacheBust = new Date().getTime()
        return requests
          .get(`/api/connectivity_logs/${roomId}/${userId}/${cacheBust}`)
          .then((res) => res.data)
      },
      getLogByStreamId: (roomId: string, streamId: string): Promise<IPostConnectivityLog> => {
        const cacheBust = new Date().getTime()
        return requests
          .get(`/api/connectivity_logs/stream/${roomId}/${streamId}/${cacheBust}`)
          .then((res) => res.data)
      },
    }

    const Organization = {
      getFeatures: (orgId: string): Promise<IOrganizationFeatures> =>
        requests.get(`/api/organizations/${orgId}/features`).then((res) => res.data),
    }

    const Room = {
      getRoom: (roomId: string): Promise<RoomAPI> =>
        requests.get(`/api/rooms/${roomId}`).then((res) => res.data),
      getFeatures: (roomId: string): Promise<IRoomFeatures> =>
        requests.get(`/api/rooms/${roomId}/features`).then((res) => res.data),
      getRoomInvites: (roomId: string): Promise<RoomInvitesAPI> =>
        requests.get(`/api/rooms/${roomId}/invites`).then((res) => res.data),
      deleteInvite: (roomId: string, email: string, role: string): Promise<void> =>
        requests.delete(`/api/rooms/${roomId}/invites`, { email, role }),
      createInvites: (roomId: string, emails: string[], role: string): Promise<void> =>
        requests.post(`/api/rooms/${roomId}/invites`, { emails, role }),
      authorization: (roomHash: string): Promise<IRoomAuthorize> =>
        requests.get(`/api/rooms/${roomHash}/authorization`).then((res) => res.data),
      doNotDisturb: (roomId: string, roomLock: boolean): Promise<void> =>
        requests.post(`/api/rooms/${roomId}/dnd`, { roomLock }),
      getMinimalRoom: (roomHash: string): Promise<RoomMinimal> =>
        requests.get(`/api/rooms/byHash/${roomHash}/minimal`).then((res) => res.data),
      getRoomServerURL: (roomId: string): Promise<RoomServerUrl> =>
        requests.get(`/api/rooms/${roomId}/server`).then((res) => res.data),
      getRoomAccess: (roomHash: string): Promise<RoomAccess> =>
        requests.get(`/api/rooms/byHash/${roomHash}/access`).then((res) => res.data),
      joinRoom: (roomId: string): Promise<void> =>
        requests.post(`/api/rooms/legacy/${roomId}/join`, {}).then((res) => res.data),
      getEBSConfig: (roomId: string): Promise<EBSConfig> => 
        requests.get(`/api/rooms/${roomId}/streams/config`).then((res) => res.data),
    }

    const Permission = {
      getPermissions: (params): Promise<IPermission> =>
        requests.post('/api/permissions', params).then((res) => res.data),
      getUserPermissions: (): Promise<any> =>
        requests.get('/api/permissions/me').then((res) => res.data),
      getRoomPermissions: (id: string): Promise<IRoomPermissions> =>
        requests.get(`/api/permissions/me/room/${id}`).then((res) => res.data),
    }

    const InviteV1 = {
      getInvite: (roomId: string): Promise<string> =>
        requests.get(`/api/rooms/${roomId}/invites/special`).then((res) => res.data),
      validateSpecial: (roomId: string, params): Promise<SpecialInviteValidationResponse> =>
        requests
          .post(`/api/rooms/${roomId}/invites/validateSpecial`, params)
          .then((res) => res.data),
    }

    const Dashboard = {
      getRooms: (): Promise<RoomAPI[]> =>
        requests.get('/api/dashboard/me/rooms').then((res) => res.data),
    }

    const SpecialInvite = {
      generate: (roomId: string): Promise<string> =>
        requests.get(`/api/rooms/${roomId}/invites/special/generate`).then((res) => res.data),
      validateSpecial: (roomId: string, params): Promise<SpecialInviteValidationResponse> =>
        requests
          .post(`/api/rooms/${roomId}/invites/special/validateSpecial`, params)
          .then((res) => res.data),
      accept: (roomId: string, params): Promise<any> =>
        requests
          .post(`/api/rooms/${roomId}/invites/special/accept`, params)
          .then((res) => res.data),
      activate: (roomId: string, params): Promise<string> =>
        requests
          .post(`/api/rooms/${roomId}/invites/special/activate`, params)
          .then((res) => res.data),
    }

    return {
      AuthAccount,
      Account,
      Dashboard,
      ConnectivityLogs,
      Organization,
      Permission,
      Room,
      InviteV1,
      SpecialInvite,
    }
  }, [baseURL, authURL])
}

export default useApi
