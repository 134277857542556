import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { StreamState, Stream as StreamType } from '../../../../../../Common/media/stream'
import { useCSDContext, CSDErrorCodes } from '../../../../../../Providers/CSDProvider'
import { DamStream } from '../LiveRoom/hooks/DamStream'

/**
 * SinkId is still experimental, we need to add it ourselves
 */
declare global {
  interface HTMLMediaElement {
    setSinkId?(sinkId: string | undefined | null): Promise<undefined>
  }
}

interface StreamProps {
  className?: string
  stream: StreamType
  volume?: number
  isMuted
  setVideoNode?: (el: HTMLVideoElement | null) => void
  watermarkElement?: JSX.Element
  isReceivingVideo?: boolean
  isMainStreamPlayer?: boolean
}

/**
 * React component that binds {@link MediaStream} to the {@link HTMLVideoElement}
 * @param className
 * @param stream
 * @param sinkId
 * @param isMuted
 * @constructor
 */
const StreamPlayer: React.FC<StreamProps> = ({
  className,
  stream,
  isMuted,
  setVideoNode,
  volume = 1,
  watermarkElement = null,
  isReceivingVideo = true,
  isMainStreamPlayer = false,
}) => {
  const videoElementRef = useRef<HTMLVideoElement | null>(null)
  const audioElementRef = useRef<HTMLVideoElement | null>(null)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const [damStream, setDamStream] = useState<DamStream | null>(null)
  const [speaker, setSpeaker] = useState<MediaDeviceInfo | null>(null)
  const { sendEntryToCSD } = useCSDContext()

  useEffect(() => {
    if (stream.state === StreamState.available && audioElementRef.current) {
      audioElementRef.current.volume = volume
    }
  }, [stream, volume])

  useEffect(() => {
    if (videoElementRef.current && setVideoNode) {
      setVideoNode(videoElementRef.current)
    }

    if (stream.state === StreamState.available && !(stream as DamStream).src) {
      setMediaStream(stream.current)
      setSpeaker(stream.speaker)
      setDamStream(null)
    } else if (stream.state === StreamState.available && (stream as DamStream).src) {
      setDamStream(stream as DamStream)
      setMediaStream(null)
      setSpeaker(stream.speaker)
    } else if (stream.state === StreamState.error) {
      sendEntryToCSD(CSDErrorCodes.noAudio)
    } else {
      setMediaStream(null)
      setSpeaker(null)

      if (videoElementRef.current) {
        videoElementRef.current.dispatchEvent(new Event('ec-loadmetadata'))
      }
    }
  }, [stream, setVideoNode, sendEntryToCSD])

  useEffect(() => {
    const videoElem = videoElementRef.current
    const audioElem = audioElementRef.current
    if (!videoElem || !audioElem) {
      return
    }
    if (mediaStream) {
      videoElem.srcObject = isReceivingVideo ? mediaStream : null;
      audioElem.srcObject = mediaStream
    } else if (damStream) {
      videoElem.srcObject = null
      audioElem.srcObject = null
      damStream.addVideoReference(
        videoElementRef as MutableRefObject<HTMLVideoElement>,
        {
          useToTrackTimeUpdate: !isMainStreamPlayer /* The thumbnail video element determines the currentTime of the stream */,
        }
      )
    } else {
      videoElem.srcObject = null
      audioElem.srcObject = null
    }

    if (videoElementRef.current) {
      videoElementRef.current.dispatchEvent(new Event('ec-loadmetadata'))
    }
  }, [mediaStream, isReceivingVideo, damStream, videoElementRef, audioElementRef, isMainStreamPlayer])

  useEffect(() => {
    const audioElem = audioElementRef.current
    if (!audioElem || !audioElem.setSinkId) {
      return
    }

    audioElem.setSinkId(speaker?.deviceId ?? 'default')
  }, [speaker])

  return (
    <>
      <audio
        style={{ display: 'none' }}
        className={className}
        muted={isMuted}
        autoPlay={true}
        playsInline={true}
        ref={audioElementRef}
      />
      <video
        className={className}
        muted={true}
        autoPlay={true}
        playsInline={true}
        ref={videoElementRef}
      />
      {watermarkElement}
    </>
  )
}

export default React.memo(StreamPlayer)
